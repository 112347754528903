.font-weight-bold {
    @apply font-bold;
}

.about-pricing {
    @apply pb-5 mb-5;

    h2 {
        @apply mb-4;
    }

    .alertBox {
        @apply mb-6;
    }
}

.auto-pricing-vehicles {
    @apply p-5 md:p-10 text-white rounded bg-primary;

    h2 {
        @apply text-hero italic text-white mt-0;
    }

    .cars {
        @apply sm:columns-2;
    }

    .car-brand {
        @apply break-inside-avoid;
    }

    h3 {
        @apply h2 text-white mt-0;
    }
}
